import React from 'react'
import NickelWeldingConsumablesStoreCarousel from './nickel-welding-consumables-store-carousel/NickelWeldingConsumablesStoreCarousel';
import NickelWeldingConsumablesStoreProductList from './nickel-welding-consumables-store-product-list/NickelWeldingConsumablesStoreProductList';

const NickelWeldingConsumablesStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <NickelWeldingConsumablesStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <NickelWeldingConsumablesStoreProductList />

            </div>
        </>
    )
}

export default NickelWeldingConsumablesStore;