import React from 'react'
import NickelThermalSprayPowderStoreCarousel from './nickel-thermal-spray-powder-store-carousel/NickelThermalSprayPowderStoreCarousel';
import NickelThermalSprayPowderStoreProductList from './nickel-thermal-spray-powder-store-product-list/NickelThermalSprayPowderStoreProductList';

const NickelThermalSprayPowderStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <NickelThermalSprayPowderStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <NickelThermalSprayPowderStoreProductList />

            </div>
        </>
    )
}

export default NickelThermalSprayPowderStore;