import React from 'react'

const WeldingConsumablesStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Consumable Welding</h2>

                <p>Cobalt alloys are used where resistance to heat and oxidation is a big factor like in the aircraft and aerospace industries.</p>

                <p>If you watched the video on your left , you noticed the hot section of the engine colored red. It runs red hot and it takes a special alloy to withstand that kind of heat and stress from pressure and vibration.</p>

                <p>Cobalt alloys usually contain some combination of cobalt, nickel, chromium, and tungsten. And are very heat and abrasive resistant.</p>

                <div className="show-case-image d-flex justify-content-center">
                    <img src="./images/consumables-welding/image-01.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/consumables-welding/image-02.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/consumables-welding/image-03.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/consumables-welding/image-04.jpg" className="card-img-top p-2" alt="..." />
                </div>
                <img src="./images/consumables-welding/chart-image.png" className="card-img-top mt-3" alt="..." />
            </div>

            
        </>
    )
}

export default WeldingConsumablesStoreProductList;