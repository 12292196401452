import React from 'react'

const ThermalSprayPowderStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Thermal Spray Powders</h2>

                <p>Thermal spraying is a surface coating technique which provides great flexibility regarding the types of materials applied. It increases the surface properties in various ways and it addresses a multitude of industrial problems.</p>

                <h2>Applications</h2>

                <p> <b>Wear Resistant Coatings</b></p>
                <p> Thermal Spray Technologies offers an almost endless variety of coating solutions to provide wear resistance. Wear mechanisms including erosion, abrasion, adhesion, cavitation, fretting, and corrosion can all be addressed using Thermal Spray Technologies engineered coatings.</p>

                <p> <b>Corrosion Protection</b></p>
                <p> Thermal Spray Technologies has the ability of producing coatings that resist corrosion in several industrial environments. Materials such as oxide ceramics and metal alloys containing nickel and chromium are commonly used.</p>

                

                <div className="show-case-image d-flex justify-content-center">
                    <img src="./images/thermal-spray-powder/image-01.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/thermal-spray-powder/image-02.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/thermal-spray-powder/image-03.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/thermal-spray-powder/image-04.jpg" className="card-img-top p-2" alt="..." />
                </div>
                <img src="./images/thermal-spray-powder/table.png" className="card-img-top mt-3" alt="..." />
            </div>


        </>
    )
}

export default ThermalSprayPowderStoreProductList;