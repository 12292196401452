import React from "react";

// CUSTOM CSS IMPORT
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> ABOUT US</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "100px" }} />
        </div>
      </div>

      <div className="page-container container">
        <h4>You need quality stainless steel and high nickel alloys?</h4>
        <p>
        Thank you for considering SL Growth Engineering LLP for your stainless steel and nickel alloys needs. We offer a wide range of products in different shapes and sizes, so you can find the right solution for your project.
        </p>
        <p>
          With SL Growth Engineering LLP, you're guaranteed quality stainless
          steel and high nickel alloys that meet your specific needs. We offer a
          wide range of products in different shapes and sizes, so you can find
          the right solution for your project.
        </p>
        <p>
        Our years of experience mean that we know exactly what you need. We also offer a wide range of delivery options so you can get your materials where you need them most. Plus, our customer service team is available 24/7 to help you with any questions or problems.
        </p>
        <p>
        Please let us know what you need, and we will provide you a quote for the stainless steel and nickel alloys that meet your specific needs. Thank you again for considering SL Growth Engineering LLP for your needs.
        </p>
      </div>
    </>
  );
};

export default AboutUs;
