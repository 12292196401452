import React from 'react'

// custom CSS import
import './navbar.css'

// react modules import
import { Link } from "react-router-dom";

// Custom Components import
import SocialMedia from '../socialMedia/SocialMedia'

const Navbar = () => {
    return (
        <>
            <div className="navbar-container">
                <nav className="navbar navbar-expand-lg navbar-dark bg-light ">
                    <div className="container navbar-items-wraper  container-fluid">
                        <div className="logo-section">
                            <Link className="navbar-brand" to="/"><img className='navbar-logo' src="./images/logo/sl-growth-logo.png" alt="loading..." /></Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-spacing">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Products
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/coted-electrodes">Coated Electrode</Link></li>
                                        {/* https://www.shantimetal.com/aws-class-enicrfe-2-coated-welding-electrodes-stockist-supplier.html */}
                                        <li><Link className="dropdown-item" to="/mig-and-tig">MIG & TIG</Link></li>
                                        {/* https://www.shantimetal.com/mig-tig-filler-welding-wire-stockist-supplier.html */}
                                        <li><Link className="dropdown-item" to="/saw-and-flex">SAW & Flux</Link></li>
                                        {/* https://www.shantimetal.com/saw-flux-filler-welding-wire-stockist-supplier.html */}
                                        <li><Link className="dropdown-item" to="/flux-core-wire">Flux Core Wire</Link></li>
                                        {/* https://www.shantimetal.com/flux-core-welding-wire-stockist-supplier.html */}
                                        <li><Link className="dropdown-item" to="/grinding-cutting-wheels">Grinding/Cutting Wheels</Link></li>
                                        {/* https://www.tyrolit.me/divisions/metal-industries/applications.html */}
                                        {/* <li><Link className="dropdown-item" to="/tig-rods-high-alloyed">TIG rods - high alloyed</Link></li>
                                        <li><Link className="dropdown-item" to="/solid-wire-unalloyed">Solid wire - unalloyed</Link></li> */}
                                        <li><Link className="dropdown-item" to="/cobalt-alloy">Cobalt Alloy</Link></li>
                                        <li><Link className="dropdown-item" to="/nickel-alloy">Nickel Alloy</Link></li>
                                        <li><Link className="dropdown-item" to="/hard-facing-alloy">Hard Facing Alloy</Link></li>
                                        <li><Link className="dropdown-item" to="/stainless-steel">Stainless Steel</Link></li>
                                        <li><Link className="dropdown-item" to="/welding-consumables">Welding Consumables</Link></li>
                                        <li><Link className="dropdown-item" to="/exaton">Exaton</Link></li>
                                        <li><Link className="dropdown-item" to="/ampco">Ampco</Link></li>
                                        <li><Link className="dropdown-item" to="/kemppi">Kemppi</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/about-us" tabIndex="-1" aria-disabled="true">About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/contact" tabIndex="-1" aria-disabled="true">Contact Us</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link " to="/cosmetic-store" tabIndex="-1" aria-disabled="true">New Arrivals</Link>
                                </li> */}

                            </ul>
                            <form className="d-flex">
                                <SocialMedia />
                                {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button> */}
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar
