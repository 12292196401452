import React from 'react'
import ForgedAndHtComponentsStoreCarousel from './forged-and-ht-components-store-carousel/ForgedAndHtComponentsStoreCarousel';
import ForgedAndHtComponentsStoreProductList from './forged-and-ht-components-store-product-list/ForgedAndHtComponentsStoreProductList';

const ForgedAndHtComponentsStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <ForgedAndHtComponentsStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <ForgedAndHtComponentsStoreProductList />

            </div>
        </>
    )
}

export default ForgedAndHtComponentsStore;