import React from 'react'
import NickelAlloyStoreCarousel from './nickel-alloy-store-carousel/NickelAlloyStoreCarousel'
import NickelAlloyStoreProductList from './nickel-alloy-store-product-list/NickelAlloyStoreProductList'

const NickelAlloyStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL COMPONENTS */}
                <NickelAlloyStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <NickelAlloyStoreProductList />

            </div>
        </>
    )
}

export default NickelAlloyStore