import React from 'react'
import CastAndMachinedStoreCarousel from './cast-and-machined-store-carousel/CastAndMachinedStoreCarousel';
import CastAndMachinedStoreProductList from './cast-and-machined-store-product-list/CastAndMachinedStoreProductList';

const CastAndMachinedStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <CastAndMachinedStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <CastAndMachinedStoreProductList />

            </div>
        </>
    )
}

export default CastAndMachinedStore;