import React from 'react'

const NickelWeldingConsumablesStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Welding Consumables</h2>

                <p>Nickel is a relatively simple metal. It is face centred cubic and undergoes no phase changes as it cools from melting point to room temperature; similar to a stainless steel. Nickel and its alloys cannot therefore be hardened by quenching so cooling rates are less important than with, say, carbon steel and preheating if the ambient temperature is above 5°C is rarely required. Nickel and its alloys are used in a very wide range of applications - from high temperature oxidation and creep resistance service to aggressive corrosive environments and very low temperature cryogenic applications. Nickel may be used in a commercially pure form but is more often combined with other elements to produce two families of alloys - solid solution strengthened alloys and precipitation hardened alloys. Typical compositions of some of the more common alloys are given in the Table.</p>

                <div className="show-case-image d-flex justify-content-center">
                    <img src="./images/nickel-welding-consumable/image-01.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/nickel-welding-consumable/image-02.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/nickel-welding-consumable/image-03.jpg" className="card-img-top p-2" alt="..." />
                </div>
                <img src="./images/nickel-welding-consumable/table-01.png" className="card-img-top mt-3" alt="..." />
                <img src="./images/nickel-welding-consumable/table-02.png" className="card-img-top mt-3" alt="..." />

                <b>Note:</b>
                <ul>
                    <li>Wire diameter 0.8,1.0,1.2,1.6,2.4,3.2mm wire weight 5 kgs precision layer wound.</li>
                    <li>Rods : Dia 1,1.2,1.6,2.0,2.4,3.2,4.0 mm length 1000mm. packing in 5kgs paper carton</li>
                </ul>
            </div>


        </>
    )
}

export default NickelWeldingConsumablesStoreProductList;