import React from "react";

// Custom Components import
import HomeCarousel from "./home-carousel/HomeCarousel";
import HomePageProductList from "./our-home-product-list/HomePageProductList";
import TopAvailableBrand from "./top-available-brand/TopAvailableBrand";
// import TopSellingProduct from "./top-selling-products/TopSellingProducts";

const Home = () => {
  return (
    <>
      {/* HOME CAROUSEL */}
      <HomeCarousel />

      {/* TOP AVAILABLE BRANDS WITH US */}
      <TopAvailableBrand />

      {/* <TOP SELLING PRODUCTS /> */}
      {/* <TopSellingProduct /> */}

      {/* ABOUT SECTION */}
      {/* <div className="centered" id="heading-centered">
        <h1 style={{ color: "black" }}> ABOUT US</h1>
        <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
      </div> */}

      <h2 className="text-center">ABOUT US</h2>
      <hr style={{ opacity: "1", color: "#ffbb33", width: "100px",height:"0.2rem", margin:"auto" }} />
      <div className="page-container container p-3">
        <h4>You need quality stainless steel and high nickel alloys?</h4>
        <p>
          Thank you for considering SL Growth Engineering LLP for your stainless
          steel and nickel alloys needs. We offer a wide range of products in
          different shapes and sizes, so you can find the right solution for
          your project.
        </p>
        <p>
          With SL Growth Engineering LLP, you're guaranteed quality stainless
          steel and high nickel alloys that meet your specific needs. We offer a
          wide range of products in different shapes and sizes, so you can find
          the right solution for your project.
        </p>
        <p>
          Our years of experience mean that we know exactly what you need. We
          also offer a wide range of delivery options so you can get your
          materials where you need them most. Plus, our customer service team is
          available 24/7 to help you with any questions or problems.
        </p>
        <p>
          Please let us know what you need, and we will provide you a quote for
          the stainless steel and nickel alloys that meet your specific needs.
          Thank you again for considering SL Growth Engineering LLP for your
          needs.
        </p>
      </div>

      {/* RENDER HOME PRODUCT LIST */}
      <HomePageProductList />
    </>
  );
};

export default Home;
