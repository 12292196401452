import React from 'react'

const CobaltAllyStoreProductList = () => {
  return (
    <>
      {/* NORMAL PRODUCT PAGE */}
      <div className="page-description-container container">

        <h2 className="page-heading text-center p-3">Cobalt Alloy</h2>

        <p>As a group, the cobalt-base alloys may be generally described as wear resistant, corrosion resistant, and heat resistant (strong even at high temperatures). Many of the properties of the alloys arise from the crystallographic nature of cobalt (in particular its response to stress), the solid-solution-strengthening effects of chromium, tungsten, and molybdenum, the formation of metal carbides, and the corrosion resistance imparted by chromium. Generally the softer and tougher compositions are used for high-temperature applications such as gas-turbine vanes and buckets. The harder grades are used for resistance to wear.</p>

        <p>Historically, many of the commercial cobalt-base alloys are derived from the cobalt-chromium-tungsten and cobalt-chromium-molybdenum ternaries first investigated by Elwood Haynes in the beginning of 20th century. He discovered the high strength and stainless nature of the binary cobalt-chromium alloy, and he later identified tungsten and molybdenum as powerful strengthening agents within the cobalt-chromium system. When he discovered these alloys, Haynes named them the cobalt alloys after the Latin stella (star), because of their star-like luster. Having discovered their high strength at elevated temperatures, Haynes also promoted the use of cobalt alloys as cutting tool materials.</p>

        <div className="bold-point d-flex justify-content-center">
          <div className="vertical-red-line" style={{ width: "5px", height: "50px", backgroundColor:"red", marginRight:"10px" }}></div>
          <div className="bold-details">
            <h5 className="bold-text">Cobalt-Base Wear-Resistant Alloys : Chemical Composition</h5>
            <p>Cr ~ 25-30% Mo = 1% max W = 2-15% C ~ 0.25-3.3% Fe = 3% max Ni = 3% max Si = 2% max Mn = 1% max. Co = rest of balance.</p>
          </div>
        </div>
        <div className="bold-point d-flex justify-content-center">
          <div className="vertical-red-line" style={{ width: "5px", height: "50px", backgroundColor:"red", marginRight:"10px" }}></div>
          <div className="bold-details">
            <h5 className="bold-text">Cobalt-Base High-Temperature Alloys : Chemical Composition</h5>
            <p>Cr ~ 25-30% Mo = 1% max W = 2-15% C ~ 0.25-3.3% Fe = 3% max Ni = 3% max Si = 2% max Mn = 1% max. Co = rest of balance.</p>
          </div>
        </div>
      </div>

      {/* DIRECT PRODUCT LIST */}
      <div className="container-fluid product-container d-flex flex-wrap justify-content-center">
        <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
          <img src="./images/top-selling-products/product-01.jpg" className="card-img-top" alt="..." />
          <div className="card-body">
            <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Welding Consumables</b> </h6>
            <div className="selling-price d-flex align-items-center">
              {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
            </div>
            {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
            <a href="/welding-consumables" className="btn btn-primary">View Details</a>
          </div>
        </div>
        <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
          <img src="./images/top-selling-products/product-01.jpg" className="card-img-top" alt="..." />
          <div className="card-body">
            <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Cast &amp; Machined Components</b> </h6>
            <div className="selling-price d-flex align-items-center">
              {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
            </div>
            {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
            <a href="/cast-and-machined" className="btn btn-primary">View Details</a>
          </div>
        </div>
        <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
          <img src="./images/top-selling-products/product-01.jpg" className="card-img-top" alt="..." />
          <div className="card-body">
            <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Thermal Spray Powders</b> </h6>
            <div className="selling-price d-flex align-items-center">
              {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
            </div>
            {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
            <a href="/thermal-spray-powder" className="btn btn-primary">View Details</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default CobaltAllyStoreProductList