import React from 'react'
import { Link } from 'react-router-dom'

// CUSTOM CSS IMPORT
import './footer.css';



const Footer = () => {
    return (
        <>
            {/* <!-- Remove the container if you want to extend the Footer to full width. --> */}
            <div className="container-fluid mt-5">
                {/* <!-- Footer --> */}
                <footer
                    className=" text-center text-lg-start text-white"
                    style={{ backgroundColor: "#45526e" }}
                >
                    {/* <!-- Grid container --> */}
                    <div className="container p-4 pb-0">
                        {/* <!-- Section: Links --> */}
                        <section className="">
                            {/* <!--Grid row--> */}
                            <div className="row">
                                {/* <!-- Grid column --> */}
                                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                        SL Growth Engineering LLP
                                    </h6>
                                    <p>
                                        We are the leading equipment suppliers in Mumbai, Maharashtra.
                                    </p>
                                </div>
                                {/* <!-- Grid column --> */}

                                <hr className="w-100 clearfix d-md-none" />

                                {/* <!-- Grid column --> */}
                                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
                                    <p>
                                        <Link className="text-white quick-link" to="/cobalt-alloy">Cobalt Alloy</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/nickel-alloy">Nickel Alloy</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/hard-facing-alloy">Hard Facing Alloy</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/stainless-steel">Stainless Steel</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/welding-consumables">Welding Consumables</Link>
                                    </p>
                                </div>
                                {/* <!-- Grid column --> */}

                                <hr className="w-100 clearfix d-md-none" />

                                {/* <!-- Grid column --> */}
                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                        Useful links
                                    </h6>
                                    <p>
                                        <Link className="text-white quick-link" to="/">Top Selling Products</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/">Available Brands</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/contact">Contact Us</Link>
                                    </p>
                                    <p>
                                        <Link className="text-white quick-link" to="/about-us">About Us</Link>
                                    </p>
                                </div>

                                {/* <!-- Grid column --> */}
                                <hr className="w-100 clearfix d-md-none" />

                                {/* <!-- Grid column --> */}
                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                                    <p><i className="fas fa-home mr-3"></i> Kharghar, Navi Mumbai</p>
                                    <p><i className="fas fa-home mr-3"></i> Borivali, Mumbai</p>
                                    <p><i className="fas fa-envelope mr-3"></i> info@slgrowthengg.com</p>
                                    <p><i className="fas fa-phone mr-3"></i> +91-7303777957</p>
                                    <p><i className="fas fa-phone mr-3"></i> +91-8454040474</p>
                                </div>
                                {/* <!-- Grid column --> */}
                            </div>
                            {/* <!--Grid row--> */}
                        </section>
                        {/* <!-- Section: Links --> */}

                        <hr className="my-3" />

                        {/* <!-- Section: Copyright --> */}
                        <section className="p-3 pt-0">
                            <div className="row d-flex align-items-center">
                                {/* <!-- Grid column --> */}
                                <div className=" text-center">
                                    {/* <!-- Copyright --> */}
                                    <div className="p-3"> © 2020 Copyright : &nbsp;
                                        <Link className="text-white company-name" to="/">SL Growth Engineering LLP</Link>

                                        <span className='developers-name'>Powered By : <a href="https://www.yodiso.com/" target={"_blank"} rel="noreferrer">Yodiso</a></span>
                                    </div>
                                    {/* <!-- Copyright --> */}
                                </div>
                                {/* <!-- Grid column --> */}
                            </div>
                        </section>
                        {/* <!-- Section: Copyright --> */}
                    </div>
                    {/* <!-- Grid container --> */}
                </footer>
                {/* <!-- Footer --> */}
            </div>
            {/* <!-- End of .container --> */}
        </>
    )
}

export default Footer;