import React from "react";

// CUSTOM CSS IMPORT
import "./cotedElectrode.css";

const CotedElectrode = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> COTED ELECTRODES</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container">
        <div className="type-one-product product-container">
          <h2 className="product-heading">NICKEL ALLOYS COATED ELECTRODES</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ENiCrFe-2 Coated Electrodes</li>
                <li>AWS Class ENiCrMo-4 Coated Electrodes</li>
                <li>AWS Class ENiFe-Cl Coated Electrodes</li>
                <li>AWS Class ENiCrMo-13 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ENiCrCoMo-1 Coated Electrodesc</li>
                <li>AWS Class ENiCrCoMo-1 Coated Electrodes</li>
                <li>AWS Class ENi-1 Coated Electrodes</li>
                <li>AWS Class ENiCrFe-3 Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-tow-product product-container">
          <h2 className="product-heading">Stainless Steel Coated Electrodes</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>WS Class E308/308H-16 Coated Electrodes</li>
                <li>AWS Class E308/308L-16 Coated Electrodes</li>
                <li>AWS Class E309/309H-16 Coated Electrodes</li>
                <li>AWS Class E309/309L-16 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E317L-16 Coated Electrodes</li>
                <li>AWS Class E320LR-16 Coated Electrodes</li>
                <li>AWS Class E330-16 Coated Electrodes</li>
                <li>AWS Class E347-16 Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-three-product product-container">
          <h2 className="product-heading">
            Duplex & Super Duplex Steel Coated Electrodes
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class E2209-16 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E2594-16 Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-four-product product-container">
          <h2 className="product-heading">Chrome Moly Coated Electrodes</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class E8018-B2 Coated Electrodes</li>
                <li>AWSClass E8018-B6 Coated Electrodes</li>
                <li>AWS Class E8018-B8 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E9015-B9 Coated Electrodes</li>
                <li>AWS Class E9018-B3 Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-five-product product-container">
          <h2 className="product-heading">Cobalt Welding Electrodes</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>ECOCR-A Welding Electrodes</li>
                <li>ECOCR-B Welding Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>ECOCR-C Welding Electrodes</li>
                <li>ECOCR-E Welding Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-six-product product-container">
          <h2 className="product-heading">
            Corrosion Resistant Alloys Coated Electrodes
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>HASTELLOY® B-3® Coated Electrodes</li>
                <li>HASTELLOY® C-4 Coated Electrodes</li>
                <li>HAYNES® C-22®/122 Coated Electrodes</li>
                <li>HASTELLOY® C-276 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>HASTELLOY® G-30® Coated Electrodes</li>
                <li>HASTELLOY® G-35® Coated Electrodes</li>
                <li>HASTELLOY® HYBRID-BC1® Coated Electrodes</li>
                <li>ULTIMET® Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-seven-product product-container">
          <h2 className="product-heading">
            High Temperature Alloys Coated Electrodes
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>HASTELLOY® X Coated Electrodes</li>
                <li>HASTELLOY® 112 Coated Electrodes</li>
                <li>HAYNES® 117 Coated Electrodes</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>HASTELLOY® 182 Coated Electrodes</li>
                <li>HASTELLOY® 230-W Coated Electrodes</li>
                <li>MULTIMET® Coated Electrodes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CotedElectrode;
