import React from "react";

// CUSTOM CSS IMPORT
import "./sawAndFlux.css";

const SawAndFlux = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> SAW & FLUX</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container">
        <div className="type-one-product product-container">
          <h2 className="product-heading">Nickel Alloys SAW & Flux</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ERNiCu-7 SAW & Flux</li>
                <li>AWS Class ERNiCr-3 SAW & Flux</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ERNiCrMo-3 SAW & Flux</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-tow-product product-container">
          <h2 className="product-heading">Stainless Steel SAW & Flux</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ER308/308H SAW & Flux</li>
                <li>AWS Class ER308/308L SAW & Flux</li>
                <li>AWS Class ER309/309L SAW & Flux</li>
                <li>Alloy 309LMo SAW & Flux</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ER316/316L SAW & Flux</li>
                <li>AWS Class ER317L SAW & Flux</li>
                <li>AWS Class ER347 SAW & Flux</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-three-product product-container">
          <h2 className="product-heading">
            Duplex & Super Duplex Steel SAW & Flux
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>Duplex & Super Duplex Steel SAW & Flux</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>{/* <li>AWS Class E2594-16 Coated Electrodes</li> */}</ul>
            </div>
          </div>
        </div>

        <div className="type-four-product product-container">
          <h2 className="product-heading">Chrome Moly SAW & Flux</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class EB2 SAW & Flux</li>
                <li>AWS Class EB3 SAW & Flux</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class EB6 SAW & Flux</li>
                <li>AWS Class EB8 SAW & Flux</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SawAndFlux;
