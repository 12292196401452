import React from "react";

const MigAndTig = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> MIG & TIG</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "100px" }} />
        </div>
      </div>

      <div className="page-container container">
        <div className="type-one-product product-container">
          <h2 className="product-heading">Nickel Alloys MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ERNiCrMo-4 MIG & TIG</li>
                <li>AWS Class ERNiCrMo-13 MIG & TIG</li>
                <li>AWS Class ERNiCu-7 MIG & TIG</li>
                <li>AWS Class ERNi-1 MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ERNiCrCoMo-1 MIG & TIG</li>
                <li>AWS Class ERNiCrMo-10 MIG & TIG</li>
                <li>AWS Class ERNiCrMo-3 MIG & TIGc</li>
                <li>AWS Class ERNiCrMo-3 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-tow-product product-container">
          <h2 className="product-heading">Stainless Steel MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ER308/308H MIG & TIG</li>
                <li>AWS Class ER308/308L MIG & TIG</li>
                <li>AWS Class ER308LSi MIG & TIG</li>
                <li>AWS Class ER309/309L MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ER316LSi MIG & TIG</li>
                <li>AWS Class ER317L MIG & TIG</li>
                <li>AWS Class ER320LR MIG & TIG</li>
                <li>AWS Class ER330 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-three-product product-container">
          <h2 className="product-heading">
            Duplex & Super Duplex Steel MIG & TIG
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ER2209 MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ER2594 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-four-product product-container">
          <h2 className="product-heading">Chrome Moly MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ER80S-B2</li>
                <li>AWS Class ER80S-B6 MIG & TIG</li>
                <li>AWS Class ER80S-B8 MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ER90S-B3 MIG & TIG</li>
                <li>AWS Class ER90S-B9 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-five-product product-container">
          <h2 className="product-heading">Titanium MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ERTi-1 MIG & TIGc</li>
                <li>AWS Class ERTi-1 MIG & TIG</li>
                <li>AWS Class ERTi-5 MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ERTi-7 MIG & TIG</li>
                <li>AWS Class ERTI-9 MIG & TIG</li>
                <li>AWS Class ERTI-12 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-six-product product-container">
          <h2 className="product-heading">Zirconium MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ERZr2 MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ERZR-4 MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-seven-product product-container">
          <h2 className="product-heading">Aluminum MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ER4043</li>
                <li>AWS Class ER5356</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ER 5183</li>
                <li>AWS Class ER 4047</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-seven-product product-container">
          <h2 className="product-heading">Bronze Alloys MIG & TIG</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>Low Fuming - AWS Class RBCuZn-C MIG & TIG</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ERCuAI-A2 MIG & TIG</li>
                <li>AWS Class ERCu MIG & TIG</li>
                <li>AWS Class ERCuSi-A MIG & TIG</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MigAndTig;
