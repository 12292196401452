import React from 'react';

// cusom CSS import
import './topAvailableBrand.css'

// third party library import
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TopAvailableBrand = () => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className=' brands-logo-section'>
                <h2 className='px-2'>Available Top Brand</h2>
                <div className='px-2'>We have most of the top brands Available with us</div>
                <hr className='mx-2' />
                <Slider {...settings} className="brand-logo-container">
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/ador.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/bholer-welding.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/tyrolit.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/norton-grinding.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/magniflux.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/pmet.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/dupoint.jpg" alt="loading..." />
                    </div>
                    <div className=" mx-2 brands-logo " style={{ maxWidth: "15rem", height: "auto" }}>
                        <img src="./images/brands-logo/ansel.jpg" alt="loading..." />
                    </div>
                    

                </Slider>
            </div>
        </>
    )
}

export default TopAvailableBrand;