import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

// import the routes components
import Navbar from './core/navbar/Navbar';
import Home from './core/home-page/Home';
// import CoveredElectrodesUnalloyedStore from './core/store/covered-electrodes-unalloyed-store/CoveredElectrodesUnalloyedStore';
// import CoveredElectrodesLowAlloyedStore from './core/store/covered-electrodes-low-alloyed-store/CoveredElectrodesLowAlloyedStore';
// import CoveredElectrodesHighAlloyedStore from './core/store/covered-electrodes-high-alloyed-store/CoveredElectrodesHighAlloyedStore';
// import FluxCoredWiresUnalloyedStore from './core/store/flux-cored- wires-unalloyed-store/FluxCoredWiresUnalloyedStore';
// import TigRodsUnalloyedStore from './core/store/tig-rods-unalloyed-store/TigRodsUnalloyedStore';
// import TigRodsHighAlloyedStore from './core/store/tig-rods-high-alloyed-store/TigRodsHighAlloyedStore';
// import SolidWireUnalloyedStore from './core/store/solid-wire-unalloyed-store/SolidWireUnalloyedStore';
import BookingPage from './core/booking-page/BookingPage';
import FooterCallToAction from './core/footerCallToAction/FooterCallToAction';
import Footer from './core/footer/Footer';
import ContactUs from './core/contact-us/ContactUs';
import CobaltAlloyStore from './core/store/cobalt-alloy-store/CobaltAlloyStore';
import NickelAlloyStore from './core/store/nickel-alloy-store/NickelAlloyStore';
import HardFacingAlloyStore from './core/store/hard-facing-alloy-store/HardFacingAlloyStore';
import StainlessSteelStore from './core/store/stainless-steel-store/StainlessSteelStore';
import WeldingConsumablesStore from './core/store/welding-consumables-store/WeldingConsumablesStore';
import CastAndMachinedStore from './core/store/cast-and-machined-store/CastAndMachinedStore';
import ThermalSprayPowderStore from './core/store/thermal-spray-powder/ThermalSprayPowderStore';
import NickelWeldingConsumablesStore from './core/store/nickel-welding-consumables/NickelWeldingConsumablesStore';
import ForgedAndHtComponentsStore from './core/store/forged-and-ht-components/ForgedAndHtComponentsStore';
import NickelThermalSprayPowderStore from './core/store/thermal-spray-powder-store/NickelThermalSprayPowderStore';
import AboutUs from './core/about-us/AboutUs';
import CotedElectrode from './core/store/coted-electrode/CotedElectrode';
import MigAndTig from './core/store/mig-and-tig/MigAndTig';
import SawAndFlux from './core/store/saw-and-flux/SawAndFlux';
import FluxCoreWire from './core/store/flux-core-wire/FluxCoreWire';
import GrindingCuttingWheels from './core/store/grinding-cutting-wheels/GrindingCuttingWheels';
import Exaton from './core/store/Exaton/Exaton';
import Ampco from './core/store/Ampco/Ampco';
import Kemppi from './core/store/kemppi/Kemppi';

const ProjectRoutes = () => {
    return (
        <>
            <Router>
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    {/* <Route exact path="/covered-electrodes-unalloyed" element={<CoveredElectrodesUnalloyedStore />} /> */}
                    <Route exact path="/coted-electrodes" element={<CotedElectrode />} />
                    {/* <Route exact path="/covered-electrodes-low-alloyed" element={<CoveredElectrodesLowAlloyedStore />} /> */}
                    <Route exact path="/mig-and-tig" element={<MigAndTig />} />
                    {/* <Route exact path="/covered-electrodes-high-alloyed" element={<CoveredElectrodesHighAlloyedStore />} /> */}
                    <Route exact path="/saw-and-flex" element={<SawAndFlux />} />
                    {/* <Route exact path="/flux-cored-wires-unalloyed" element={<FluxCoredWiresUnalloyedStore />} /> */}
                    <Route exact path="/flux-core-wire" element={<FluxCoreWire />} />
                    <Route exact path="/grinding-cutting-wheels" element={<GrindingCuttingWheels />} />
                    {/* <Route exact path="/tig-rods-unalloyed" element={<TigRodsUnalloyedStore />} /> */}
                    {/* <Route exact path="/tig-rods-high-alloyed" element={<TigRodsHighAlloyedStore />} /> */}
                    {/* <Route exact path="/solid-wire-unalloyed" element={<SolidWireUnalloyedStore />} /> */}
                    <Route exact path="/cobalt-alloy" element={<CobaltAlloyStore />} />
                    <Route exact path="/nickel-alloy" element={<NickelAlloyStore />} />
                    <Route exact path="/hard-facing-alloy" element={<HardFacingAlloyStore />} />
                    <Route exact path="/stainless-steel" element={<StainlessSteelStore />} />
                    <Route exact path="/welding-consumables" element={<WeldingConsumablesStore />} />
                    <Route exact path="/cast-and-machined" element={<CastAndMachinedStore />} />
                    <Route exact path="/thermal-spray-powder" element={<ThermalSprayPowderStore />} />
                    <Route exact path="/nickel-welding-consumables" element={<NickelWeldingConsumablesStore />} />
                    <Route exact path="/nickel-forged-components" element={<ForgedAndHtComponentsStore />} />
                    <Route exact path="/nickel-thermal-spray-powder" element={<NickelThermalSprayPowderStore />} />
                    <Route exact path="/about-us" element={<AboutUs />} />
                    <Route exact path="/contact" element={<ContactUs />} />
                    <Route exact path="/booking" element={<BookingPage />} />
                    <Route exact path="/exaton" element={<Exaton />} />
                    <Route exact path="/ampco" element={<Ampco />} />
                    <Route exact path="/kemppi" element={<Kemppi />} />

                </Routes>
                <Footer />
                <FooterCallToAction />
            </Router>
        </>
    )
}

export default ProjectRoutes;