import React from "react";

// CUSTOM CSS IMPORT
import "./kemppi.css";
import { Link } from "react-router-dom";

const Kemppi = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/exatonBanner.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}>KEMPPI-PRODUCTS</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container mt-3">
        <h2 className="text-center">Kemppi-Products</h2>
      </div>
      <div className="container">
        <div>
          <h4>SMAW</h4>
        </div>
        <div className="product_list_container">

          <div className="product_detals_container">
            <img src="./images/kempi/smaw/Master-315.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none" >
              Master 315
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/smaw/Master-s-400.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Master S
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/smaw/Minarc.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Master Evo
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/smaw/MinarcEvo.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Minarc
              </Link>
              </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <h4>GMAW</h4>
        </div>
        <div className="product_list_container">

          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/FastMig-X.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none" >
              X8 MIG Welder
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/X5-FastMig.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              X5 FastMig
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/X8-MIG-Welder.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              FastMig X
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/smaw/MinarcEvo.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Master M
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/Kempact-RA.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Kempact RA
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/Kempact-MIG.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Kempact MIG
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/MinarcMig-Evo.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              MinarcMig Evo
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/gmaw/FitWeld-Evo.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              FitWeld Evo
              </Link>
              </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <h4>SPARE PARTS</h4>
        </div>
        <div className="product_list_container">

          <div className="product_detals_container">
            <img src="./images/kempi/spare-parts/MIG-MAG-consumables.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none" >
              MIG/MAG Consumables
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/spare-parts/TIG-consumables.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              TIG Consumables
              </Link>
              </span>
          </div>
          <div className="product_detals_container">
            <img src="./images/kempi/spare-parts/Wire-feeder-consumables.jpg" alt="Product One" className="kemppiProductImage" />
            <span className="clickableButton">
              <Link to="/contact" className="text-decoration-none">
              Wire Feeder Consumables
              </Link>
              </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kemppi;
