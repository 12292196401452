import React from 'react'

const NickelAlloyStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Nickel Alloy</h2>

                <p>Nickel has been used in alloys that date back to the dawn of civilization. Chemical analysis of artifacts has shown that weapons, tools, and coins contain nickel in varying amounts.</p>

                <p>Nickel in elemental form or alloyed with other metals and materials has made significant contributions to our present-day society and promises to continue to supply materials for an even more demanding future.</p>

                <p>Nickel is a versatile element and will alloy with most metals. Complete solid solubility exists between nickel and copper. Wide solubility ranges between iron, chromium, and nickel make possible many alloy combinations.</p>

                <h2>Applications and Characteristics</h2>
                <p>Nickel and nickel alloys are used for a wide variety of applications, the majority of which involve corrosion resistance and/or heat resistance.</p>
            </div>

            {/* DIRECT PRODUCT LIST */}
            <div className="container-fluid product-container d-flex flex-wrap justify-content-center">
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/nickel-alloy/image-01.jpg" className="card-img-top" alt="..." style={{maxWidth:"350px"}} />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Welding Consumables</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/nickel-welding-consumables" className="btn btn-primary">View Details</a>
                    </div>
                </div>
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/nickel-alloy/image-02.jpg" className="card-img-top" alt="..." style={{maxWidth:"350px"}} />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Nickel Forged Components</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/nickel-forged-components" className="btn btn-primary">View Details</a>
                    </div>
                </div>
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/nickel-alloy/image-03.jpg" className="card-img-top" alt="..." style={{maxWidth:"350px"}} />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Thermal Spray Powders</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/nickel-thermal-spray-powder" className="btn btn-primary">View Details</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NickelAlloyStoreProductList