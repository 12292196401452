import React from 'react'

const CobaltAllyStoreCarousel = () => {
    return (
        <>
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                {/* CAROUSEL IMAGE LIST */}
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="./images/home-banner/home-banner-02.jpg" className="d-block w-100" alt="..." />
                    </div>
                </div>

                {/* CAROUSEL HANDLER */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

export default CobaltAllyStoreCarousel