import React from 'react'

const ForgedAndHtComponentsStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Forged &amp; HT Components</h2>

                <p>The commercial forms of nickel and nickel-base alloys are fully austenitic and are used/selected mainly for their resistance to high temperature and aqueous corrosion.</p>
                <div className="bold-point d-flex justify-content-center">
                    <div className="vertical-red-line" style={{ width: "5px", height: "50px", backgroundColor: "red", marginRight: "10px" }}></div>
                    <div className="bold-details">
                        <h5 className="bold-text">The nickel-chromium and nickel-chromium-iron</h5>
                        <p>resistance to elevated temperatures. Today they also form the basis for both commercial and military power systems.</p>
                    </div>
                </div>
                <h5 className="bold-text">Some High-Temperature Variants include:</h5>
                <ul>
                    <li>Alloy 601. Lower nickel (61%) content with aluminum and silicon additions for improved oxidation and nitriding resistance.</li>
                    <li>Alloy X750. Aluminum and titanium additions for age hardening.</li>
                    <li>Alloy 718. Titanium and niobium additions to overcome strainage cracking problems during welding and weld repair.</li>
                    <li>Alloy X (48Ni-22Cr-18Fe-9Mo + W). High-temperature flat-rolled product for aerospace applications.</li>
                </ul>
                <p><b>Some Corrosion-Resistant Variants in the Ni-Cr-Fe System include:</b></p>
                <p><b>Alloy 625 - </b>The addition of 9% Mo plus 3% Nb offers both high-temperature and wet corrosion resistance; resists pitting and crevice corrosion​</p>

                <h5 className="bold-text">Iron-Nickel-Chromium Alloys</h5>
                <p>This series of alloys has also found extensive use in the high-temperature petrochemical environments, where sulfur-containing feedstocks (naphtha and heavy oils) are cracked into component distillate parts. Not only were they resistant to chloride-ion stress-corrosion cracking, but they also offered resistance to polythionic acid cracking. Some alloys of commercial importance include:</p>
                <ul>
                    <li>Alloy 800 (Fe-32Ni-21Cr). The basic alloy in the Fe-Ni-Cr system; resistant to oxidation and carburization at elevated temperatures.</li>
                    <li>​​Alloy 825 (Fe-42Ni-21.5Cr-2Cu). Stabilized with titanium addition (0.6 to 1.2%). Also contains molybdenum (3%) for pitting resistance in aqueous corrosion applications. Copper content bestows resistance to sulfuric acid.</li>
                </ul>


                <img src="./images/nickel-frodge-ht/table-01.png" className="card-img-top mt-3" alt="..." />

            </div>
        </>
    )
}

export default ForgedAndHtComponentsStoreProductList;