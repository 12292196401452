import React from 'react'

const StainlessSteelStoreProductList = () => {
  return (
    <>
      {/* NORMAL PRODUCT PAGE */}
      <div className="page-description-container container">

        <h2 className="page-heading text-center p-3">Stainless Steels</h2>

        <h2>Stainless Steels are commonly divided into five groups:</h2>
        <ul>
          <li>martensitic stainless steels</li>
          <li>ferritic stainless steels</li>
          <li>austenitic stainless steels</li>
          <li>duplex (ferritic-austenitic) stainless steels</li>
          <li>precipitation-hardening stainless steels.​</li>
        </ul>

        <p>Stainless steels are iron-based alloys containing at least 10.5% Cr. Few stainless steels contain more than 30% Cr or less than 50% Fe. They achieve their stainless characteristics through the formation of an invisible and adherent chromium-rich oxide surface film.</p>

        <p>This oxide forms itself in the presence of oxygen Other elements added to improve characteristics include nickel, molybdenum, copper, titanium, aluminum, silicon, niobium, nitrogen, sulfur, and selenium. Carbon is normally present in amounts ranging from less than 0.03% to over 1.0% in certain martensitic grades.</p>

        <div className="d-flex">
          <img src="./images/stainless-still/table-01.jpg" className="card-img-top mt-3" alt="..." />
        </div>
      </div>
    </>
  )
}

export default StainlessSteelStoreProductList;