import React from 'react'
import CobaltAllyStoreCarousel from './cobalt-alloy-store-carousel/CobaltAllyStoreCarousel'
import CobaltAllyStoreProductList from './cobalt-alloy-store-product-list/CobaltAllyStoreProductList'

const CobaltAlloyStore = () => {
  return (
    <>
    <div className="store-container">
        {/* PAGE HEADING CAROUSEL */}
        <CobaltAllyStoreCarousel />

        {/* PRODUCT LIST COMPONENT */}
        <CobaltAllyStoreProductList />
        
    </div>
    </>
  )
}

export default CobaltAlloyStore