import React from 'react'
import ThermalSprayPowderStoreCarousel from './thermal-spray-powder-carousel/ThermalSprayPowderStoreCarousel';
import ThermalSprayPowderStoreProductList from './thermal-spray-powder-product-list/ThermalSprayPowderStoreProductList';

const ThermalSprayPowderStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <ThermalSprayPowderStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <ThermalSprayPowderStoreProductList />

            </div>
        </>
    )
}

export default ThermalSprayPowderStore;