import React from "react";

// CUSTOM CSS IMPORT
import "./exaton.css";

const Exaton = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/exatonBanner.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}>Exaton</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container mt-3">
        <h2 className="text-center">EXATON</h2>

        <img src="./images/exaton/exaton_details_01.png" alt="Details" />
      </div>
      <div className="page-container container">
        <h4 className="text-center mt-1">STAINLESS STEEL-REGULAR GRADES</h4>
        <img src="./images/exaton/exaton_details_02.png" alt="Details" />
      </div>
      <div className="page-container container">
        <h4 className="text-center mt-1">STAINLESS STEEL-OTHER GRADES</h4>
        <img src="./images/exaton/exaton_details_03.png" alt="Details" />
      </div>
    </>
  );
};

export default Exaton;
