import React from 'react'
import WeldingConsumablesStoreCarousel from './welding-consumables-store-carousel/WeldingConsumablesStoreCarousel';
import WeldingConsumablesStoreProductList from './welding-consumables-store-product-list/WeldingConsumablesStoreProductList';

const WeldingConsumablesStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <WeldingConsumablesStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <WeldingConsumablesStoreProductList />

            </div>
        </>
    )
}

export default WeldingConsumablesStore;