import React from "react";

// CUSTOM CSS IMPORT
import "./ampco.css";

const Ampco = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/exatonBanner.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}>AMPCO</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container mt-3">
        <h2 className="text-center">AMPCO</h2>
        <img src="./images/ampco/ampco_01.png" alt="Details" />
      </div>
    </>
  );
};

export default Ampco;
