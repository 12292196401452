import React from "react";

// CUSTOM CSS IMPORT
import "./grindingCuttingWheels.css";

const GrindingCuttingWheels = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> GRINDING/CUTTING WHEELS</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "300px" }} />
        </div>
      </div>

      {/* PRODUCTS LIST */}
      <div className="product-page-container container">
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/external-cylindrical-grinding.jpg" alt="External Cylindrical Grinding..." />
            <h4 > External cylindrical grinding</h4>
            <p>Tools for external cylindrical grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/centerless-grinding.jpg" alt="Centerless Grinding grinding..." />
            <h4 > Centerless Grinding grinding</h4>
            <p>Tools for centerless grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/double-side-face-grinding.jpg" alt="Double Side Face Grinding..." />
            <h4 > Double Side Face Grinding</h4>
            <p>Tools for Double Side Face Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/fining-grinding.jpg" alt="Fine Grinding..." />
            <h4 > Fine Grinding</h4>
            <p>Tools for Fine Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/surface-and-creep-feed-grinding.jpg" alt="Surface and Creep Feed Grinding..." />
            <h4 > Surface and Creep Feed Grinding</h4>
            <p>Tools for Surface and Creep Feed Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/internal-cylindrical-grinding.jpg" alt="Internal Cylindrical Grinding..." />
            <h4 > Internal Cylindrical Grinding</h4>
            <p>Tools for Internal Cylindrical Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/fettling.jpg" alt="Fettling..." />
            <h4 > Fettling</h4>
            <p>Tools for Fettling.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/honing.jpg" alt="Honing / Finishing..." />
            <h4 > Honing / Finishing</h4>
            <p>Tools for Honing / Finishing.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/high-pressure-grinding.jpg" alt="High Pressure Grinding..." />
            <h4 > High Pressure Grinding</h4>
            <p>Tools for High Pressure Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/saw-sharpening.jpg" alt="Saw Sharpening..." />
            <h4 > Saw Sharpening</h4>
            <p>Tools for Saw Sharpening.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/rail-maintenance.jpg" alt="Rail Maintenance..." />
            <h4 > Rail Maintenance</h4>
            <p>Tools for Rail Maintenance.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/cut-off-grinding.jpg" alt="Cut Off Grinding..." />
            <h4 > Cut Off Grinding</h4>
            <p>Tools for Cut Off Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/gear-honing.jpg" alt="Gear Honing..." />
            <h4 > Gear Honing</h4>
            <p>Tools for Gear Honing.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/gear-grinding.jpg" alt="Gear Grinding..." />
            <h4 > Gear Grinding</h4>
            <p>Tools for Gear Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
        <div className="individual-product">
            <img src="./images/grinding-cutting-wheel/tool-grinding.jpg" alt="Tool Grinding..." />
            <h4 > Tool Grinding</h4>
            <p>Tools for Tool Grinding.</p>
            <button type="button" className="btn btn-primary"><a href="/contact" className="product-button-text">View More Details</a></button>
        </div>
      </div>
    </>
  );
};

export default GrindingCuttingWheels;
