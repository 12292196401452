import React from 'react'
import HardFacingAlloyStoreCarousel from './hard-facing-alloy-store-carousel/HardFacingAlloyStoreCarousel'
import HardFacingAlloyStoreProductList from './hard-facing-alloy-store-product-list/HardFacingAlloyStoreProductList'

const HardFacingAlloyStore = () => {
  return (
    <>
        <div className="store-container">
        {/* PAGE HEADING CAROUSEL */}
        <HardFacingAlloyStoreCarousel />

        {/* PRODUCT LIST COMPONENT */}
        <HardFacingAlloyStoreProductList />
        
    </div>
    </>
  )
}

export default HardFacingAlloyStore