import React from 'react'

const BookingPage = () => {
    return (
        <>
            <div className="booking-container">
                <div className="left-side-elements-container">
                    <div className="card text-center">
                        <div className="card-header">
                            As of now we are only serving offline order !
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">We accept order on the telephone and provide free home delivery with cash on delivery option</h5>
                            <h2 className="card-title">Call : + 91 7303777957</h2>
                            <p className="card-text">SL Growth Engineering LLP, 21st Floor, Kamdhenu Commerz, Sector-14, Kharghar, Navi Mumbai, Maharashtra -410210</p>
                            <p className="card-text">Yodiso India Pvt. Ltd, 910, Kamdhenu Commerz, Sector-14, Kharghar, Navi Mumbai, Maharashtra -410210</p>
                                {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                        </div>
                        <div className="card-footer text-muted">
                            Call: + 91 9584584101
                        </div>
                    </div>
                    {/* google map */}
                    <iframe title="googleMap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15085.76577745111!2d73.0730045!3d19.0443191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7bcbc171a1816d60!2sYodiso%20India%20-%20Web%20Development%20and%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2sin!4v1666284271544!5m2!1sen!2sin"
                    style={{width: "100%", height:"450px", border: "o"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                </div>
                {/* <div className="right-side-elements-container">

                </div> */}
            </div>
        </>
    )
}

export default BookingPage