import React from 'react'
import StainlessSteelStoreCarousel from './stainless-steel-store-carousel/StainlessSteelStoreCarousel'
import StainlessSteelStoreProductList from './stainless-steel-store-product-list/StainlessSteelStoreProductList'

const StainlessSteelStore = () => {
    return (
        <>
            <div className="store-container">
                {/* PAGE HEADING CAROUSEL */}
                <StainlessSteelStoreCarousel />

                {/* PRODUCT LIST COMPONENT */}
                <StainlessSteelStoreProductList />

            </div>
        </>
    )
}

export default StainlessSteelStore