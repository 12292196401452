import React from 'react'

const HardFacingAlloyStoreProductList = () => {
    return (
        <>

            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Hard Facing Alloy</h2>

                <p>Hardfacing is a metalworking process where harder or tougher material is applied to a base metal. It is welded to the base material, and generally takes the form of specialized electrodes for arc welding or filler rod for oxyacetylene and TIG welding. Powder metal alloys are used in (PTA) also called Powder plasma welding system and Thermal spray processes like HVOF, Plasma spray, Fuse and spray etc.</p>

                <p>Hardfacing may be applied to a new part during production to increase its wear resistance, or it may be used to restore a worn-down surface. Hardfacing by arc welding is a surfacing operation to extend the service life of industrial components, pre-emptively on new components, or as part of a maintenance program. The result of significant savings in machine down time and production costs has meant that this process has been adopted across many industries such as Steel, Cement, Mining, Petrochemical, Power, Sugar cane and Food. According to the results of an experimental study, the SMAW (Shielded Metal Arc Welding) and the GMAW (Gas Metal Arc Welding) hardfacing processes were effective in reducing the wear on the mouldboard ploughshare. With the SMAW and GMAW hardfacing processes, the life span of the ploughshare was increased approximately 2 times.</p>


                <h2>Hardfacing can be deposited by various welding methods:</h2>
                <ul>
                    <li>Shielded Metal Arc Welding (SMAW)</li>
                    <li>Gas Metal Arc Welding (GMAW), including both gas-shielded and open arc welding</li>
                    <li>Oxyfuel Welding (OFW)</li>
                    <li>Submerged Arc Welding (SAW)</li>
                    <li>Electroslag Welding (ESW)</li>
                    <li>Plasma Transferred Arc Welding (PTAW), also called Powder Plasma welding system</li>
                    <li>Thermal spraying</li>
                </ul>
            </div>

            <h2 className='container'>Range of Hard Facing Alloys</h2>
            <div className="container product-container d-flex flex-wrap ">
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/hard-facing-alloy/image-01.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Cobalt Hard Facing</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/welding-consumables" className="btn btn-primary">View Details</a>
                    </div>
                </div>
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/hard-facing-alloy/image-02.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Nickel Hard Facing</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/nickel-welding-consumables" className="btn btn-primary">View Details</a>
                    </div>
                </div>
            </div>

            <h2 className='container'>Range OF Hard Facing Powders</h2>
            <div className="container product-container d-flex flex-wrap ">
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/hard-facing-alloy/image-03.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Cobalt Base Powder</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/thermal-spray-powder" className="btn btn-primary">View Details</a>
                    </div>
                </div>
                <div className="card mx-2 my-2 product-details " style={{ minWidth: "15rem", height: "auto" }}>
                    <img src="./images/hard-facing-alloy/image-04.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h6 className="card-title" style={{ maxWidth: "15rem" }}> <b>Nickel Base Powder</b> </h6>
                        <div className="selling-price d-flex align-items-center">
                            {/* <h6 className="card-text">Selling Price </h6> <h4><b> ₹799</b></h4> */}
                        </div>
                        {/* <p style={{ textDecoration: "line-through", marginTop: "-13px" }}> ₹1295</p> */}
                        <a href="/nickel-thermal-spray-powder" className="btn btn-primary">View Details</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HardFacingAlloyStoreProductList;