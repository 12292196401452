import React from "react";

// CUSTOM CSS IMPORT
import "./homePageProductList.css";

const HomePageProductList = () => {
  return (
    <>
      <h2 className="text-center">OUR PRODUCTS</h2>
      <hr
        style={{
          opacity: "1",
          color: "#ffbb33",
          width: "150px",
          height: "0.2rem",
          margin: "auto",
        }}
      />
      <div className="home-product-list-container container">
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/coated-electrode.jpg"
            alt="Coated Electrode..."
          />
          <h4> Coated Electrode</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/coted-electrodes" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/mig-tig.jpg"
            alt="MIG & TIG..."
          />
          <h4> MIG & TIG</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/mig-and-tig" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/saw-flux.jpg"
            alt="Saw & Flux..."
          />
          <h4> Saw & Flux</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/saw-and-flex" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/flux-core-wire.jpg"
            alt="Flux Core Wire..."
          />
          <h4> Flux Core Wire</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/flux-core-wire" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/grinding-cutting-wheels.jpg"
            alt="Grinding / Cutting Wheels..."
          />
          <h4> Grinding/Cutting Wheels</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/grinding-cutting-wheels" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/cobalt-alloy.jpg"
            alt="Cobalt Alloy..."
          />
          <h4> Cobalt Alloy</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/cobalt-alloy" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/nickel-alloy.jpg"
            alt="Nickel Alloy..."
          />
          <h4> Nickel Alloy</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/nickel-alloy" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/hard-facing-alloy.jpg"
            alt="Hard Facing Alloy..."
          />
          <h4> Hard Facing Alloy</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/hard-facing-alloy" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/stainless-steel.jpg"
            alt="Stainless Steel..."
          />
          <h4> Stainless Steel</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/stainless-steel" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
        <div className="home-individual-product">
          <img
            src="./images/home-page-products-list/welding-consumable.jpg"
            alt="Welding Consumable..."
          />
          <h4> Welding Consumable</h4>
          {/* <p>Tools for external cylindrical grinding.</p> */}
          <button type="button" className="btn btn-primary home-view-more-button">
            <a href="/welding-consumables" className="product-button-text">
              View More Details
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default HomePageProductList;
