import React from 'react'

const NickelThermalSprayPowderStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Thermal Spray Powders</h2>
                <h4 className="page-heading ">PTA & Laser Cladding</h4>

                <p>Plasma transferred arc (PTA) hardfacing is a versatile method of depositing high-quality metallurgically fused deposits on relatively low cost surfaces. Soft alloys, medium and high hardness materials, and carbide composites can be deposited on a variety of substrates to achieve diverse properties such as mechanical strength, wear and corrosion resistance, and creep. PTA hardfacing has several significant advantages over traditional welding processes such as oxyfuel (OFW) and gas tungsten arc (GTAW) welding.</p>
               
                <img src="./images/nickel-thermal-spray/table-01.png" className="card-img-top mt-3" alt="..." />
                <img src="./images/nickel-thermal-spray/table-02.png" className="card-img-top mt-3" alt="..." />

            </div>
        </>
    )
}

export default NickelThermalSprayPowderStoreProductList;