import React from "react";

// CUSTOM CSS IMPORT
import "./fluxCoreWire.css";

const FluxCoreWire = () => {
  return (
    <>
      {/*PAGE HEADING */}
      <div className="Header">
        <img
          className="img-fluid"
          src="./images/page-header/contact-page-header.jpg"
          alt="loading..."
          style={{ width: "100%" }}
        />
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "white" }}> FLUX CORE WIRE</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "200px" }} />
        </div>
      </div>

      <div className="page-container container">
        <div className="type-one-product product-container">
          <h2 className="product-heading">Nickel Alloys Flux Core</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class ENiCr3T1-1/T1-4 Flux Core</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class ENiCrMo3T1-1/T1-4 Flux Core</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-tow-product product-container">
          <h2 className="product-heading">Stainless Steel Flux Core</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class E308HT1-1/T1-4 Flux Core</li>
                <li>AWS Class E308LT0-1/T0-4 Flux Core</li>
                <li>AWS Class E308LT1-1/T1-4 Flux Core</li>
                <li>AWS Class E309LT0-1/T0-4 Flux Core</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E316LT1-1/T1-4 Flux Core</li>
                <li>AWS Class E317LT0-1/T0-4 Flux Core</li>
                <li>AWS Class E317LT1-1/T1-4 Flux Core</li>
                <li>AWS Class E347T0-1/T0-4 Flux Core</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-three-product product-container">
          <h2 className="product-heading">
            Duplex & Super Duplex Steel Flux Core{" "}
          </h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class E2209T1-1/T1-4 Flux Core</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E2594T1-1/T1-4 Flux Core</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="type-four-product product-container">
          <h2 className="product-heading">Chrome Moly Flux Core</h2>
          <div className="product-bullet-marks">
            <div className="left-side-element">
              <ul>
                <li>AWS Class E81T1-B2 Flux Core</li>
              </ul>
            </div>
            <div className="right-side-element">
              <ul>
                <li>AWS Class E91T1-B3 Flux Core</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FluxCoreWire;
