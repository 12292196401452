import React from 'react'

const CastAndMachinedStoreProductList = () => {
    return (
        <>
            {/* NORMAL PRODUCT PAGE */}
            <div className="page-description-container container">

                <h2 className="page-heading text-center p-3">Cast &amp; Machined Components</h2>

                <p>Cobalt (Co) is a lustrous, silvery-blue, non-ferrous metal found in cobaltite, smaltite, and erythrite minerals. Cobalt alloys are metal alloys containing cobalt as the base material. They exhibit high strength and toughness, excellent high temperature strength and resistance, and good corrosion resistance.</p>

                <p> <b>Wear Resistant</b> alloys are those that are made to resist abrasive wear, sliding wear, and erosive wear. They consist mostly of cobalt and chromium but may also contain tungsten or molybdenum and a small amount of carbon.</p>

                <p> <b>Heat Resistant</b> alloys are those that are made to resist elevated-temperature strength and resistance to thermal fatigue. Although they are not as widely used as nickel based high-temperature alloys, they play a role in temperatures above nickel’s deterioration point and in applications where sulfidation resistance is important.</p>
                <p> <b>Corrosion Resistant</b> alloys are those that are designed to resist aqueous corrosion. They are limited in their corrosion resistance in comparison to nickel-chromium-molybdenum alloys. However, several low-carbon, wrought cobalt-nickel-chromium-molybdenum alloys exist to satisfy the need for a corrosion resistant cobalt base alloy</p>

                <div className="show-case-image d-flex justify-content-center">
                    <img src="./images/cast-and-machined/image-01.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/cast-and-machined/image-02.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/cast-and-machined/image-03.jpg" className="card-img-top p-2" alt="..." />
                    <img src="./images/cast-and-machined/image-04.jpg" className="card-img-top p-2" alt="..." />
                </div>
                <img src="./images/cast-and-machined/table.png" className="card-img-top mt-3" alt="..." />
            </div>


        </>
    )
}

export default CastAndMachinedStoreProductList;